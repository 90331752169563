import React, { useEffect, useRef, useState } from "react";
import { Upload } from "@/utils/upload";
import { Wifi } from "lucide-react"; // Import the Lucide icon

const uploader = new Upload();

const NetworkSpeedMonitor = ({ id }) => {
  const [downlink, setDownlink] = useState(0);
  const [uplink, setUplink] = useState(0);
  const [colorUp, setColorUp] = useState("red");
  const [colorDown, setColorDown] = useState("red");
  const isOnlineRef = useRef(false);

  const handleOnline = () => {
    // console.log("**CONEXION***");
    setColorUp("#ffe500");
    isOnlineRef.current = true;
  };

  const handleOffline = () => {
    // console.log("**FALLA CONEXION***");
    setColorUp("red");
    isOnlineRef.current = false;
  };


  const measureNetworkSpeed = async () => {
    //await measureDownloadSpeed();
    // console.log("**PETICION***", isOnlineRef.current);
    if (isOnlineRef.current) {
      await measureUploadSpeed();
    }
  };

  const measureDownloadSpeed = async () => {
    const imageUrl = "https://s3.amazonaws.com/certifiedspanish.com/coffee.jpg"; // Replace with a small image URL
    const startTime = new Date().getTime();

    try {
      const response = await fetch(imageUrl);
      const endTime = new Date().getTime();

      const fileSize = 117713; // Size of the image in bytes (adjust accordingly)
      const duration = (endTime - startTime) / 1000; // Duration in seconds

      if (duration > 0.1) {
        const speedMbps = (fileSize * 8) / (duration * 1024 * 1024); // Convert to Mbps
        setDownlink(speedMbps);
        updateColor(speedMbps, "down");
      } else {
        console.warn("Download duration too short for accurate measurement");
      }
    } catch (error) {
      console.error("Error measuring download speed:", error);
    }
  };

  const measureUploadSpeed = async () => {
    // Create a large SVG content by repeating a simple shape
    const repeatedShapes = Array(10000)
      .fill(
        `
    <circle cx="50" cy="50" r="40" stroke="black" stroke-width="3" fill="red" />
  `,
      )
      .join("");

    const svgContent = `
    <svg xmlns="http://www.w3.org/2000/svg" width="1000" height="1000">
      ${repeatedShapes}
    </svg>
  `;
    const blob = new Blob([svgContent], {
      type: "image/svg+xml",
    });
    const fileName = `upstream-test-${id}.svg`;
    const file = new File([blob], fileName, { type: blob.type });
    const startTime = new Date().getTime();

    try {
      const response = await uploader.uploadPhotoId(file, fileName);
      /*const response = await fetch("http://httpbin.org/post", {
        method: "POST",
        body: blob,
      });*/
      const endTime = new Date().getTime();

      if (response && response?.Key) {
        const duration = (endTime - startTime) / 1000; // Duration in seconds
        if (duration > 0.1) {
          const speedMbps = (file.size * 8) / (duration * 1024 * 1024); // Convert to Mbps
          setUplink(speedMbps);
          updateColor(speedMbps, "up");
        } else {
          console.warn("Upload duration too short for accurate measurement");
        }
      } else {
        // updateColor(0, "up");
        setColorUp("red");
        console.error("Upload failed:", response.statusText);
      }
    } catch (error) {
      // updateColor(0, "up");
      setColorUp("red");
      console.error("Error measuring upload speed2:", error);
    }
  };

  const updateColor = (speed, dir) => {
    console.log({ speed, dir });
    if (dir === "down") {
      if (speed < 1) {
        setColorDown("red"); // Slow network
      } else if (speed < 3) {
        setColorDown("#ffe500"); // Moderate network
      } else {
        setColorDown("green"); // Good network
      }
    } else {
      if (speed < 1) {
        // setColorUp("red"); // Slow network
        setColorUp("#ffe500"); // Slow network
      } else if (speed < 2) {
        setColorUp("#ffe500"); // Moderate network
      } else {
        setColorUp("green"); // Good network
      }
    }
  };

  useEffect(() => {
    const intervalId = setInterval(measureNetworkSpeed, 10000); // Measure every 10 seconds

    // Initial measurement
    measureNetworkSpeed();

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);
    navigator.onLine ? handleOnline() : handleOffline();
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);



  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Wifi color={colorUp} size={24} />
      {/*<ArrowDown color={colorDown} size={24} />*/}
    </div>
  );
};

export default NetworkSpeedMonitor;
